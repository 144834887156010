<template>
  <div style="height: auto">
    <div class="title">
    <div style="width: 250px">
        <img src="../static/logo.png" alt="logo.jpg" style="width: 250px;height: 100px">
    </div>
    <div class="option"  v-for="item in titlelist" :key="item">
      <router-link class="option" active-class="optionactive" :to="{path:item.target}">
        <div style="width: 100%;height: 100%;color: white">
        {{item.title}}
      </div>
      </router-link>
    </div>
    </div>
    <div>
      <TopBar style="width: auto"/>
    </div>
    <div class="content">
    <router-view></router-view>
    </div>
  </div>
</template>
<style scoped>
  .title{
    background-color: #2F609C;
    width: 250px;
    height: 100%;
    float: left;
  }
  .content{
    width: calc(100% - 250px);
    height: calc(100% - 105px);
    float: left;
  }
  .option{
    width: 250px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: inherit;
    text-decoration: none;
  }
  .option:hover,.option:active{
    background-color: #66ffff;
  }
  .optionactive{
    background-color: #1E90FF;
  }
  .optionactive div{
    background-color: #1E90FF;
  }

</style>
<script lang="js" setup>
  import router from "@/router";
  import Cookies from "js-cookie";
  import $ from "jquery";
  import axios from 'axios'
  import {ref} from 'vue'
  import Swal from "sweetalert2";

  let titlelist=ref([

  ])
  $(document).ready(function () {
    // 用axios以ajax方式发送post请求
    axios({
      method: "post",
      url: "/api/limitmag/selectBatList"
    }).then(function (resp) {
      // 若用户是登录状态则返回用户名,否则显示登陆注册
      titlelist.value=resp.data.data
      console.log(titlelist.value)
    }).catch(function (error) {
      Swal.fire({
        heightAuto: false,
        icon: 'error',
        title: "未能获取菜单列表",
        showConfirmButton: false,
        timer: 1500
      })
    })
  })
</script>
<script lang="js">
import TopBar from "@/components/TopBar";

export default {
  name: "TitleBtn",
  components: {
    TopBar

  }
}
</script>

<style scoped>

</style>