import { createApp } from 'vue'
import App from './App.vue'
import TitleBtn from "./components/TitleBtn"
import Login from "./components/Login"
import TopBar from "./components/TopBar"
import router from './router/index'
import ElementPlus from 'element-plus'
import $ from 'jquery'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'element-plus/dist/index.css'
import Cookies from 'js-cookie';
const app = createApp(App)
app.config.warnHandler = () => null
app.use(TitleBtn)
app.use(router)
app.use(Cookies)
app.use(Login)
app.use(TopBar)
app.use(ElementPlus)
app.use(VueAxios , axios)
app.use($)
app.mount("#app")