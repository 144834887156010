const Personnel = () => import('../mag/Personnel.vue')
const Account = () => import('../mag/Account.vue')
const Limitmag = () => import('../mag/Limitmag.vue')
const Shop = () => import('../mag/Shop.vue')
const TopBar = () => import('../components/TopBar.vue')
const Login = () => import('../components/Login.vue')
const routes=[
    {
        path:'/mag/personnel',
        component:Personnel,
    },
    {
        path:'/mag/account',
        component:Account,
    },
    {
        path:'/mag/limitmag',
        component:Limitmag,
    },
    {
        path:'/mag/shop',
        component:Shop,
    },
    {
        path:'/components/TopBar',
        component:TopBar,
    },
    {
        path:'/components/Login',
        component:Login,
    },

];
export default routes