<template>
  <div class="topbar">
    <el-button class="topbarbtn" type="primary" @click="logout" plain>退出登录</el-button>
    <el-button class="topbarbtn" type="primary" @click="changepwd" plain>修改密码</el-button>
    <span class="topbarbtn" style="color: white">当前登录账号为:{{accountusername}}</span>
    <el-dialog v-model="changePwdVisible" draggable="true">
      <el-form :inline="true"  ref="formRef" :model="form" :rules="rules" label-width="70px">
        <el-form-item label="原密码" prop="oripwd">
          <el-input v-model="form.oripwd"  show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newpwd">
          <el-input v-model="form.newpwd"  show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="changePwdVisible = false">取消</el-button>
        <el-button type="primary" @click="savenewpwd">修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script  lang="js">
export default {
  name: "TopBar"
}
</script>
<script lang="js" setup>
import axios from "axios";
import $ from "jquery";
import {ref} from 'vue'
import Swal from 'sweetalert2'
import {ElMessage} from 'element-plus'

// 获取form表单引用
const formRef = ref();
let changePwdVisible = ref(false);
let accountusername = ref();
import {reactive} from 'vue'
import router from "@/router";
import Cookies from "js-cookie";

let form = reactive({
  oripwd:'',
  newpwd:''
})
const rules = reactive({
  oripwd: [
    {required: true, message: '请输入旧密码', trigger: 'blur'},
    {min: 6, max: 16, message: '旧密码长度在6-16个字之间', trigger: 'blur'},
  ],
  newpwd: [
    {required: true, message: '请输入新密码', trigger: 'blur'},
    {min: 6, max: 16, message: '新密码长度在6-16个字之间', trigger: 'blur'},
  ]

})
function logout() {
  Cookies.remove("id")
  Cookies.remove("verifycode")
  location.href = '/'
}
function changepwd(){
  changePwdVisible.value=true
}
$(document).ready(function () {
  // 用axios以ajax方式发送post请求
  axios({
    method: "post",
    url: "/api/account/getaccountusername"
  }).then(function (resp) {
    // 若用户是登录状态则返回用户名,否则显示登陆注册
    accountusername.value=resp.data.data
  }).catch(function (error) {
    accountusername.value="未能获取当前用户名称"
  })
})
function savenewpwd() {
  formRef.value.validate(valid=> {
    if (valid) {
      axios({
        method: "post",
        url: "/api/account/changepwd",
        data: "data=" + JSON.stringify(form)
      }).then(function (resp) {
        let type = 'error'
        if (resp.data.code===1){
          type='success'
          ElMessage({
            message: resp.data.data,
            type: type,
          })
          Cookies.remove("id")
          Cookies.remove("verifycode")
          location.href = '/'
        }else {
          ElMessage({
            message: resp.data.msg,
            type: type,
          })
        }
      }).catch(function (error) {
        console.log(error)
        ElMessage({
          message: "修改失败",
          type: 'error',
        })
      })
    } else {
      ElMessage({
        message: "请检查必填项",
        type: 'error',
      })
    }
  })
}
</script>
<style scoped>
.topbar{
  height: 105px;
  width: auto;
  background-color: #2F609C;
}
.topbarbtn{
  float: right;
  margin-left: 20px;
  margin-top: 50px;
}
</style>