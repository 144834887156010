<template>
  <div class="lgb">
  <div class="login">
    <div style="width: 200px;height: 300px"></div>
    <div class="logreg">
      <el-row>
        <el-col :span="10"></el-col>
        <el-col :span="5">
          <!--      选择行编辑-->
          <div style="width: 250px;margin-left: 0px">
            <img src="../static/logo.png" alt="logo.jpg" style="width: 380px">
          </div>
          <el-form  :model="form"  ref="formRef" :rules="rules" label-width="100px">
            <el-form-item label="账号" prop="username">
              <el-input v-model="form.username" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pwd">
              <el-input v-model="form.pwd" show-password style="width: 200px"></el-input>
            </el-form-item>
<!--            <el-form-item label="是否记住登录" prop="ifsave">-->
<!--              <el-checkbox v-model="form.ifsave" label="是否记住登录" size="large" />-->
<!--            </el-form-item>-->
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="login" class="logregbtn">登录</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  </div>
</template>

<script lang="js" setup>
import axios from "axios";
import $ from "jquery";
import Swal from 'sweetalert2'
import {ElMessage} from 'element-plus'

let personnellist = ref([]);
let dialogVisible = ref(false);
let newdataVisible = ref(false);
// 获取form表单引用
const formRef = ref();
let deleteConfirmDialogVisible = ref(false);
let deleteChoiceConfirmDialogVisible = ref(false);
let temprow = ref([]);
let page = ref({
  current: '1',
  size: '',
  pages: '',
  total: '',
  pervious: '',
  hasNext: '',
  records: ''
});
import {reactive} from 'vue'
import router from "@/router";

let form = reactive({
  username: '',
  pwd:'',
  ifsave:false
})
const rules = reactive({
  username: [
    {required: true, message: '请输入用户名', trigger: 'blur'},
    {min: 2, max: 30, message: '姓名长度在2-30个字之间', trigger: 'blur'},
  ],
  pwd: [
    {required: true, message: '请输入密码', trigger: 'blur'},
    {min: 2, max: 30, message: '姓名长度在2-30个字之间', trigger: 'blur'},
  ]
})


function login() {
  formRef.value.validate(valid=> {
    if (valid) {
      // 表单所有元素通过验证
      console.log('验证通过');
      axios({
        method: "post",
        url: "/api/account/login",
        data: "data=" + JSON.stringify(form)
      }).then(function (resp) {
        let type = 'error'
        if (resp.data.code===1){
          type='success'
          location.href = '/'
        }
        ElMessage({
          message: resp.data.msg,
          type: type,
        })
      }).catch(function (error) {
        console.log(error)
        ElMessage({
          message: "登录失败",
          type: 'error',
        })
      })
    } else {
      console.log('验证不通过');
    }
  })
  $(document).ready(function () {
    // 用axios以ajax方式发送post请求
    axios({
      method: "post",
      url: "/api/account/checkiflogin"
    }).then(function (resp) {
      console.log(iflogin.value)
      if(resp.data.map.iflogin==='true'){
        location.href = '/'
      }
    }).catch(function (error) {
    })
  })
}
</script>

<script lang="js">
import {ref} from 'vue';
import {ElTable, ElTableColumn, ElButton, ElDialog, ElForm, ElFormItem, ElInput, ElMessage} from 'element-plus';
import Swal from "sweetalert2";

export default {
  name: "Personnel",
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
  }
}


</script>

<style scoped>
.personnel {
  width: 100%;
  height: 100%;
  background-color: #55ccdd;
  float: left;
}

.logreg {
  height: 650px;
}
.logregbtn{
  width: 200px;
  margin-left: 100px;
}
.lgb{
  background-image: url("../static/background.jpg");
}
::v-deep .el-form-item__label{
  color: white;
  font-size: 18px;
}
</style>