<template>
    <div class="titlebtnstyle" v-if="iflogin==='true'">
      <TitleBtn style="height: 100%" />
    </div>
  <div v-if="iflogin==='false'">
  <router-view  :key="$route.fullPath"></router-view>
  </div>
</template>
<script lang="js" setup>
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
} from '@element-plus/icons-vue'
import Cookies from 'js-cookie';
import {ref} from 'vue'
import Swal from "sweetalert2";
import router from "@/router";
    let map=[];
    let iflogin=ref();
    $(document).ready(function () {
      // 用axios以ajax方式发送post请求
      axios({
        method: "post",
        url: "/api/account/checkiflogin"
      }).then(function (resp) {
        // 若用户是登录状态则返回用户名,否则显示登陆注册
        iflogin.value=resp.data.map.iflogin
        console.log(iflogin.value)
        if(resp.data.map.iflogin==='false'){
          router.push({path:'/components/Login'})
        }
      }).catch(function (error) {
        Cookies.remove("id")
        Cookies.remove("verifycode")
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          title: "未能获取登录状态",
          showConfirmButton: false,
          timer: 1500
        })
      })
    })
</script>
<script lang="js">
//dom加载完成后立即执行下边的语句
import axios from "axios";
import $ from "jquery";
import TopBar from "@/components/TopBar";
import TitleBtn from "@/components/TitleBtn";
export default {
  name: 'App',
  components: {
    TopBar,
    TitleBtn
  }
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
}
.titlebtnstyle{
  height: 100%;
}
html,body{
  margin: 0px;
  height: 100%;
  background-color: #2F609C;
}
.operationbtn{
  margin: 7px;
}
.btnarea{
  height: 50px;
  width: 100%;
  background-color: #6C7B8B;
}
</style>
